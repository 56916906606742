<template>
  <v-footer color="transparent" class="text-lg-start">
    <v-card flat tile width="100%" outlined color="transparent">
      <v-container fill-height>
        <v-row no-gutters class="black--text" align="center" justify="center">
          <p class="text-body-1 mx-7" style="text-align: justify">
            <i class="fa fa-info-circle" aria-hidden="true"></i> Pour avoir plus
            d'informations variées vous pouvez télécharger l'application
            <b>Mercanews</b> via Google Play
            <a
              href="https://play.google.com/store/apps/details?id=com.mercarue.mercanews&hl=fr"
              target="_blank"
              rel="noopener noreferrer"
              style="border: medium none; text-decoration: none"
            >
              ici
            </a>
            et accédez à toutes les informations !
          </p>
        </v-row>
        <v-row no-gutters class="black--text" align="center" justify="center">
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-subheader
              id="titre"
              class="text-body-1 mx-4"
              style="font-weight: bold"
            >
              Mercarue
            </v-subheader>

            <p class="text-body-1 mx-7" style="text-align: justify">
              Mercarue est une entreprise active dans le domaine des
              technologies de l'information (IT). Avec une équipe dynamique,
              talentueuse et passionnée par les nouvelles technologies.
            </p>
          </v-col>

          <v-col cols="12" md="3" sm="12" xs="12">
            <v-list color="transparent" flat>
              <v-subheader class="text-body-1 mx-5" style="font-weight: bold"
                >Contact</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in contact"
                  :key="i"
                  :href="item.href"
                  dense
                  target="_black"
                >
                  <v-icon class="mx-5" :color="item.color">{{
                    item.icon
                  }}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-body-2"
                      v-text="item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col cols="12" md="3" sm="12" xs="12">
            <v-list color="transparent" flat>
              <v-subheader class="text-body-1 mx-5" style="font-weight: bold"
                >Suivez Nous !</v-subheader
              >
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in socicialMedia"
                  :key="i"
                  :href="item.href"
                  dense
                  target="_blank"
                >
                  <v-icon class="mx-5" :color="item.color">{{
                    item.icon
                  }}</v-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-2" v-text="item.text">
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-text class="py-2 black--text text-center">
        {{ new Date().getFullYear() }} — <strong>Par Mercarue</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import "firebase/compat/firestore";
export default {
  data: () => ({
    contact: [
      {
        text: "www.mercarue.com",
        href: "https://mercarue.com",
        icon: "mdi-web",
        color: "blue",
      },
      {
        text: "Contact@mercarue.com",
        href: "mailto:Contact@mercarue.com",
        icon: "mdi-email-open-outline",
        color: "blue",
      },
      {
        text: "+216 31 400 244",
        icon: "mdi-phone",
        color: "black",
      },
    ],
    socicialMedia: [
      {
        text: "Facebook",
        href: "https://www.facebook.com/mercanews.officiel",
        icon: "mdi-facebook",
        color: "blue",
      },
      {
        text: "Instagram",
        href: "https://www.instagram.com/mercanews.officiel/",
        icon: "mdi-instagram",
        color: "brown",
      },
      {
        text: "Youtube",
        href: "https://www.youtube.com/channel/UCawEEHUjvtwqRlVB5WgtxBA",
        icon: "mdi-youtube",
        color: "red",
      },
    ],
  }),
};
</script>
