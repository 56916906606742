import db from "./../utils/firebaseInit";
import NodesName from "./nodesName";
import NodesFieldName from "./nodesFieldName";
import FunctionUtils from "./../utils/functions";

export default {
  getArticleFromDatabase: async (
    numberMaxToGetRandom,
    category,
    sourceLogo
  ) => {
    return db
      .collection(NodesName.RSS_FEED)
      .where(NodesFieldName.CATEGORY, "==", category)
      .where(NodesFieldName.ARCHIVED, "==", false)
      .orderBy(NodesFieldName.ISODATE, "desc")
      .limit(5)
      .get()
      .then((querySnapshot) => {
        var articles = [];
        querySnapshot.forEach((doc) => {
          articles.push({
            category: doc.data().libelle,
            title:
              doc.data().title.length > 60
                ? doc.data().title.substring(0, 60) + "..."
                : doc.data().title,
            description: doc.data().description,
            image: FunctionUtils.getStringImageQuality(doc.data().image),
            sourceName: doc.data().sourceName,
            sourceImage: sourceLogo.get(doc.data().sourceName),
            link: doc.data().link,
            uniqueId: doc.data().uniqueId,
          });
        });
        var randomReponse = FunctionUtils.getRandomInt(numberMaxToGetRandom);
        return articles[randomReponse];
      });
  },
  getArticleFromDatabaseForInfiniteScrollReference: () => {
    return db
      .collection(NodesName.RSS_FEED)
      .orderBy(NodesFieldName.ISODATE, "desc")
      .where(NodesFieldName.ARCHIVED, "==", false)
      .where(NodesFieldName.LANGUAGE, "==", "FR")
      .limit(3);
  },

  getArticleFromDatabaseForInfiniteScroll: async (fluxSnapshot, sourceLogo) => {
    var articles = [];
    fluxSnapshot.forEach((doc) => {
      articles.push({
        category: doc.data().libelle,
        title:
          doc.data().title.length > 60
            ? doc.data().title.substring(0, 60) + "..."
            : doc.data().title,
        description: doc.data().description,
        image: FunctionUtils.getStringImageQuality(doc.data().image),
        sourceName: doc.data().sourceName,
        sourceImage: sourceLogo.get(doc.data().sourceName),
        link: doc.data().link,
        uniqueId: doc.data().uniqueId,
      });
    });
    return articles;
  },
  getArticleFromDatabaseForInfiniteScrollNewsRecommande: async (
    fluxSnapshot,
    sourceLogo
  ) => {
    var articles = [];
    fluxSnapshot.forEach((doc) => {
      articles.push({
        category: doc.data().libelle,
        title: doc.data().title,
        description: doc.data().description,
        image: FunctionUtils.getStringImageQuality(doc.data().image),
        sourceName: doc.data().sourceName,
        sourceImage: sourceLogo.get(doc.data().sourceName),
        link: doc.data().link,
        uniqueId: doc.data().uniqueId,
      });
    });
    return articles;
  },
  getSourcesWithImages: async () => {
    var sourceImageMap = new Map();
    return db
      .collection("source_feed")
      .where(NodesFieldName.DELETED, "==", false)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          sourceImageMap.set(doc.data().uniqueId, doc.data().sourceImage);
        });
        return sourceImageMap;
      });
  },
  setNumberOfViews: async (fluxId) => {
    db.collection(NodesName.RSS_FEED)
      .where(NodesFieldName.UNIQUEID, "==", fluxId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            nbrConsulted: doc.data().nbrConsulted + 1,
          });
        });
      })
      .catch(function (error) {
        console.log("Error delete document:", error);
      });
  },
};
